import { IResource, IResourceFrontmatter } from '@utils/types';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import DownloadForm from '@components/DownloadForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

export default function ResourcesTemplate({
	data,
	pageContext: {
		breadcrumb: { crumbs },
	},
}: {
	data: {
		locales: any;
		markdownRemark: {
			frontmatter: IResource;
			html: string;
		};
		banner: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
		resources: { nodes: IResourceFrontmatter[] };
	};
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
}) {
	const { t } = useTranslation();

	const { markdownRemark, banner, resources } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html } = markdownRemark;

	const cover = getImage(frontmatter.image.childImageSharp.gatsbyImageData);

	const [formSent, setFormSent] = React.useState(false);
	const ref = useRef<HTMLDivElement>(null);
	const scrollToRef = ref?.current?.scrollIntoView({ behavior: 'smooth' });

	const { user } = useAuth0();
	const [submitTrigger, setSubmitTrigger] = useState(false);

	const triggerFormSubmit = () => {
		setSubmitTrigger(true);
	};

	return (
		<Page>
			<SEO
				title={`${frontmatter.title} | ${t("_Insights")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.title}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div
					ref={ref}
					className="col xl3 offset-xl1 m4 offset-m0 s12 s-spacer resource__aside">
					{cover && (
						<div className="resource__cover">
							<GatsbyImage
								image={cover}
								alt={frontmatter.title}
							/>
						</div>
					)}
					{(formSent || frontmatter.type == "Brochure" || user) && frontmatter?.download_pdf && (
						<Button to={frontmatter.download_pdf} type="download" onClick={triggerFormSubmit}>
							{t("_DownloadPDF")}
						</Button>

					)}
					<p>
						{' '}
					</p>
					{(formSent || frontmatter.type == "Brochure" || user) && frontmatter?.download_video && (
						<Button to={frontmatter.download_video} type="download" onClick={triggerFormSubmit}>
							{t("_DownloadVideo")}
						</Button>
					)}
				</div>
				<div className="col xl6 offset-xl1 l7 offset-l0 m8 s12 s-spacer page__content">
					<header className="page__header">
						<h1 className="page__title">{frontmatter.title}</h1>
					</header>
					<div dangerouslySetInnerHTML={{ __html: html }} />
					<p>
						{t("_Formerly")}
					</p>
				</div>
				<div className="col xl8 offset-xl2 l10 offset-l1 m12 offset-m0" hidden={user}>
					{frontmatter.type != "Brochure" && (frontmatter?.download_pdf || frontmatter?.download_video) &&
						(formSent ? (
							<p className="alert--success">
								{t("_ThankYouDownload")}
							</p>
						) : (
							<DownloadForm
								scrollToRef={scrollToRef}
								setFormSent={setFormSent}
								title={frontmatter.title}
								submitTrigger={submitTrigger}
							/>
						))}
				</div>
			</PageSection>

			<ResourceListing
				resources={resources}
				title={t("_OurInsights")}
				theme="greytint"
			/>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {		
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				download_pdf
				download_video
				type
			}
			html
		}
		resources: allMarkdownRemark(
			filter: { 
				fileAbsolutePath: { regex: "/resources/" } 
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 3
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}

		banner: file(relativePath: { eq: "content/banner-insights.jpg" }) {
			...imageBreaker
		}
	}
`;
