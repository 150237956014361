import { LINKS } from '@utils/constants';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import Checkbox from './forms/Checkbox';
import Input from './forms/Input';
import { User, useAuth0 } from '@auth0/auth0-react';

interface FormProps {
	firstname?: string;
	lastname?: string;
	company?: string;
	jobtitle?: string;
	email?: string;
	phone?: string;
	marketing?: boolean;
}

const DownloadForm = ({
	setFormSent,
	scrollToRef,
	title,
	submitTrigger
}: {
	setFormSent: React.Dispatch<React.SetStateAction<boolean>>;
	scrollToRef: void | undefined;
	title: string;
	submitTrigger: boolean
}) => {
	const { t } = useTranslation();

	const { user } = useAuth0();

	const [formData, setFormData] = useState<FormProps>();

	useEffect(() => {
		setFormData({
			firstname: user?.given_name || "",
			lastname: user?.family_name || "",
			company: "",
			jobtitle: "",
			email: user?.email || "",
			phone: "",
			marketing: true
		})
	}, [user]);

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = () => {
		fetch('/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: encode({
				'form-name': 'DownloadForm',
				'resource': title,
				...formData,
			}),
		})
			.then((res) => {
				setFormSent(true);
				() => scrollToRef;
			})
			.catch((error) => alert(error));
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		let value;

		switch (e.target.type) {
			case 'checkbox':
				value = e.target.checked ? e.target.checked : false;
				break;

			default:
				value = e.target.value;
				break;
		}

		setFormData({ ...formData, [name]: value });
	};

	useEffect(() => {
		if (submitTrigger) {
			handleSubmit();
		}
	})

	return (
		<form
			name="DownloadForm"
			data-netlify="true"
			netlify-honeypot="bot-field"
			method="POST"
			className="form__container has-loaded"
			//hidden={!!user}
			onSubmit={() => handleSubmit()}>
			<>
				<div className="form__container__header text--center">
					<h2 className="form__container__title">
						{t("_FormGainAccess") /* i18next-extract-disable-line */}
					</h2>
				</div>
				<label hidden>
					Don&apos;t fill this out if you&apos;re human:{' '}
					<input name="bot-field" />
				</label>
				<input type="hidden" name="form-name" value="DownloadForm" />
				<input type="hidden" name="resource" value={title} />
				<input type="hidden" name="subject" data-remove-prefix value="Form submission from DownloadForm" />
				<Input
					label={t("_FormNameFirst") /* i18next-extract-disable-line */}
					name="firstname"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					defaultValue={user?.given_name}
					className={formData?.firstname ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label={t("_FormNameLast") /* i18next-extract-disable-line */}
					name="lastname"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					defaultValue={user?.family_name}
					className={formData?.lastname ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label={t("_FormCompany") /* i18next-extract-disable-line */}
					name="company"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.company ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label={t("_FormJobTitle") /* i18next-extract-disable-line */}
					name="jobtitle"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={formData?.jobtitle ? 'form-input--filled' : ''}
					required
				/>
				<Input
					label={t("_FormEmail") /* i18next-extract-disable-line */}
					name="email"
					type="email"
					className={
						formData?.email !== undefined
							? 'form-input--filled'
							: ''
					}
					defaultValue={user?.email}
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					required
				/>
				<Input
					label={t("_FormPhone") /* i18next-extract-disable-line */}
					name="phone"
					type="tel"
					//Allow spaces and input of country code with +()
					pattern="[\s+()0-9]{1,20}"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
					className={
						formData?.phone !== undefined
							? 'form-input--filled'
							: ''
					}
				/>
				<p>
					{t("_FormPrivacyNotice") /* i18next-extract-disable-line */}{' '}
					<Link to={LINKS.PRIVACY} target="_blank">
						{t("_FormPrivacyLink") /* i18next-extract-disable-line */}
					</Link>
					.
				</p>

				<Checkbox
					label={t("_FormMarketing") /* i18next-extract-disable-line */}
					name="marketing"
					handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleChange(e)
					}
				/>

				<button className="button" type="submit">
					{t("_FormSubmit") /* i18next-extract-disable-line */}
				</button>
			</>
		</form>
	);
};

export default DownloadForm;
